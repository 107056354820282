@import url("https://fonts.googleapis.com/css?family=Roboto");

html {
  min-height: 100vh;
}

body {
  font-family: Lato;
  min-height: 100vh;
}

#root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 100vh;
  height: 100%;
  width: 100%;
}
over html,
body {
  height: 100%;
}

/*
  https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0px solid transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
