.sampleImage {
  max-width: 200px;
  width: 100%;
}

ul {
  list-style: none;
  padding-left: 0;
}

ul li {
  text-align: center;
}

.dropzoneCustom {
  background: #fefcf9;
  margin: 0 auto;
  width: 40%;
  border: 2px dotted #ffaa00;
  min-height: 15em;
  text-align: center;
  border-radius: 20px;
}

.currentImage {
  width: 90%;
  height: 100%;
  object-fit: cover;
  margin-top: 2em;
  margin-bottom: 2em;
}

@media only screen and (max-width: 600px) {
  .dropzoneCustom {
    width: 80%;
  }
}
