@media print {
  .printable {
    background: white;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 5000;
  }
  #main-wrapper {
    overflow-x: visible;
    overflow-y: visible;
  }
}
